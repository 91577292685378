import { FC } from "react"

type PlanOverviewProps={
	planName:string;
	visitsLimit: number;
}

export const PlanOverview: FC<PlanOverviewProps> = ({planName,visitsLimit}) => {
	const certificateType = planName==="Freemium"? "group" : "personal";

	const mutatePlanText=()=>{
		let plan = planName==="Freemium"? 
		planName : planName.includes("Yearly")?
		"Yearly" : "Monthly";

		if(plan!="Freemium"){
			let type;
			switch(visitsLimit){
				case 10000:
					type=' '
					return plan+type;
				case 100000:
					type=' starter'
					return plan+type;
				case 500000:
					type=' advanced'
					return plan+type;
				default:
					type=' custom'
					return plan+type;
			}
		}else{
			return plan;
		}
	}

	return(
		<div className='content-container-inner consumption-container'>
            <label className="consumption-container-title">{mutatePlanText()} plan</label>
            <hr />
            <div className='consumption-data-container'>
                <p className='consumption-data'>🗓️ <br />{certificateType} certificate</p>
                <p className='consumption-data-desc'>you will get a monthly {certificateType} certificate with this plan</p>
            </div>
            <div className='consumption-data-container'>
                <p className='consumption-data'>💻 <br /> {visitsLimit} WEBSITE VISITS</p>
                <p className='consumption-data-desc'>After one month, {visitsLimit} visits free for each next month for one year</p>
            </div>
        </div>

	)
}
import { useEffect, useState } from 'react';
import iconError from '../../images/icon-error.svg';
import iconSearch from '../../images/icon-search.svg';
import { enrexApi } from '../../services/EnrexApiService';
import { useResize } from '../../utils/Resize';
import ConsumptionPanel from '../Consumption/ConsumptionPanel';

const MembersList = () => {
  const [membersList, setMembersList] = useState<any>([]);
  const screenWidth = useResize();

  useEffect(() => {
    search();
  }, []);

  const onSearchChange = (event: any) => {
    search(event.target.value)
  }

  const search = async (website?: string) => {
    const result = await enrexApi.get('/accounts', {
      params: {
        url: website,
      },
    })
    setMembersList(result.data);
  }

  return (
    <div className="dapp-container">
      <label className="content-container-inner-label">search websites that are already using enrex <label className="label-accent">green browsing</label></label>
      <div className={(screenWidth < 800) ? "" : "content-container-inner"}>
        <div className="input-icons">
          <img className="icon" src={iconSearch} alt='' />
          <input type="search" placeholder="Website address" onChange={onSearchChange} className='search' />
        </div>
      </div>
      {membersList?.length !== 0 &&
        <div>
          {membersList?.map((element: any, i: number) => {
            return <ConsumptionPanel websiteData={element} key={i} />
          }
          )}
        </div>
      }
      {membersList?.length === 0 &&
        <div className='horizontalContainer'>
          <img src={iconError} alt='' />
          <label className='noPadding'>No results found</label></div>
      }
    </div>
  )
};

export default MembersList;

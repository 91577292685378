import moment from "moment";
import { mutatePlanName } from "./MutateSubscriptionName";

export const getWidgetDeactivatedMessage = (account: any) => {
    let message = `Your badge has been deactivated for website ${account.url} since ${reformatDate(account.subscription.subscriptionStatus.dateChanged)} `;
    if (account.triggers.PaymentIsMissing2WDeactivation_Web) {
        message += `due to payment missing for ${moment(account.subscription.subscriptionStatus.dateChanged).fromNow(true)}.`;
    } else if (account.triggers.Inactive3WDeactivation_Web) {
        message += `due to inactivity for more than ${moment(account.subscription.subscriptionStatus.dateChanged).fromNow(true)}.`;
    } else if (account.triggers.SubscriptionLimit_Web_14d) {
        message += `due to reaching the plan limit.`;
    }
    return message.trim();
}

export const getLimitExceededMessage = (account: any) => {
    return `Plan limits has been reached at ${reformatDate(account.subscription.subscriptionStatus.dateChanged)}. 
   Please upgrade your subscription - `;
}
export const getLimitExceededMessage2 = (account: any) => {
    return `Plan limits are reached ${moment(account.subscription.subscriptionStatus.dateChanged).fromNow()}.
   If plan is not upgraded for two weeks badge and subscription is deactivaded automaticallly.`;
}

export const getWidgetInactiveMessage = (account: any) => {
    return `Your badge is inactive for ${moment(account.lastCounterDate).days()} day(s), ${moment(account.lastCounterDate).days()} hour(s), since ${reformatDate(account.lastCounterDate)}. 
    After 3 weeks of inactivity, the badge is deactivated and subscription stopped automatically.`;
}

export const getPaymentMissingMessage = (account: any) => {
    return `Payment has been missing from ${reformatDate(account.subscription.subscriptionStatus.dateChanged)}. Badge with payments missing for 2 weeks are deactivated automatically.`;
}

export const getPaymentSuccess = (account: any) => {
    return `Payment of ${(getLastPayment(account)?.amount / 100)} USD received for plan ${mutatePlanName((getLastPayment(account)?.planName))}, for period ${reformatDate(account?.subscription?.currentPeriodStartDate)} - ${reformatDate(account?.subscription?.nextPeriodStartDate)}`;
}

export const getWidgetActivatedMessage = (account: any) => {
    return `Your badge was reactivated on ${reformatDate(account.widgetStatusChangeDate)}`;
}

export const getLastPayment = (account: any) => {
    let payment = account.subscription?.paymentLog.findLast((element: any) => element)
    return payment;
}

export const reformatDate = (date: any) => {
    let newDate = new Date(date).toLocaleString('en-US', { month: 'short', day: "2-digit", year: "numeric" })
    return newDate;
}
const dev = {
    enrexApi: 'http://localhost:8000/',
    enrexLanguages: 'https://assets-test.enrex.io/badget/languages',
    websiteCarbon: {
        url: 'https://api.websitecarbon.com/',
    },
    badgetSource: 'https://assets-test.enrex.io/badget/badget.js',
    pipedrive: {
        id: 21
    },
    stripeUrls: {
        Yearly500: 'https://buy.stripe.com/test_6oE7tia3Lfam6xW008',
        Monthly500: 'https://buy.stripe.com/test_cN28xma3LbYa8G4cMT',
        Yearly100: 'https://buy.stripe.com/test_4gw3d21xffam3lKaEK',
        Monthly100: 'https://buy.stripe.com/test_6oEdRGfo52nAcWk3ch',
        enterprice: 'https://calendly.com/dainiusenrex/introduction-call',
    }
};

const test = {
    enrexApi: 'https://medeina.api.dapp.enrex.io/',
    enrexLanguages: 'https://assets-test.enrex.io/badget/languages',
    websiteCarbon: {
        url: 'https://api.websitecarbon.com/',
    },
    badgetSource: 'https://assets-test.enrex.io/badget/badget.js',
    pipedrive: {
        id: 21
    },
    stripeUrls: {
        Yearly500: 'https://buy.stripe.com/test_6oE7tia3Lfam6xW008',
        Monthly500: 'https://buy.stripe.com/test_cN28xma3LbYa8G4cMT',
        Yearly100: 'https://buy.stripe.com/test_4gw3d21xffam3lKaEK',
        Monthly100: 'https://buy.stripe.com/test_6oEdRGfo52nAcWk3ch',
        enterprice: 'https://calendly.com/dainiusenrex/introduction-call',
    }
}

const prod = {
    enrexApi: 'https://api.dapp.enrex.io/',
    enrexLanguages: 'https://assets.enrex.io/badget/languages',
    websiteCarbon: {
        url: 'https://api.websitecarbon.com/',
    },
    badgetSource: 'https://assets.enrex.io/badget/badget.js',
    pipedrive: {
        id: 21
    },
    stripeUrls: {
        Yearly500: 'https://buy.stripe.com/6oE188aH3dh27ss4gi',
        Monthly500: 'https://buy.stripe.com/7sIaIIbL71ykaEE9AE',
        Yearly100: 'https://buy.stripe.com/bIYaII7uR5OAaEEbIL',
        Monthly100: 'https://buy.stripe.com/7sIdUU4iFdh27sscMR',
        enterprice: 'https://calendly.com/dainiusenrex/introduction-call',
    }
};

const config = {
    // Add common config values here
    // Default to dev if not set
    ...(process.env.REACT_APP_ENV === "prod" ? prod : process.env.REACT_APP_ENV === "test" ? test : dev),
};

export default config;

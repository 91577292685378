const throttleMap = new Map<Function, { throttledFunction: Function; args: any[] | null }>();

/**
 * Lets you call a function and have it throttled by a certain delay.
 * Useful for when a function may be spammed!
 *
 * @example
 * ```
 * function resize(w, h){
 *  console.log('resized', w, h);
 * }
 *
 * window.onResize = ()=>{
 *  throttle(resize, 100)(window.innerWidth, window.innerHeight);
 * }
 * ```
 *
 * @param fun the function you want throttled
 * @param delay how long until the function is executed
 */
export function throttle(fun: Function, delay = 100, scope?: any): Function {
	if (!throttleMap.has(fun)) {
		const throttledFunction = (...args: any[]): void => {
			throttleMap.get(fun)!.args = args;

			setTimeout(() => {
				const latestArgs = throttleMap.get(fun)!.args;

				if (scope) {
					fun.call(scope, ...latestArgs as []);
				}
				else {
					fun(...latestArgs as []);
				}
			}, delay);
		};

		throttleMap.set(fun, { throttledFunction, args: null });
	}

	return throttleMap.get(fun)!.throttledFunction;
}
import { ChartOptions } from "chart.js";

export const options: ChartOptions = {
	maintainAspectRatio: false,
	scales: {
		x: {
			grid: {
				display: false,
				drawBorder: false,
				drawOnChartArea: false,
			},
			ticks: {
				font: {
					weight: "bold"
				}
			},
		},
		yAxisRight: {
			//suggestedMax: 100,
			grid: { display: false, drawBorder: false, },
			beginAtZero: true,
			position: 'right',
			ticks: {
				font: {
					weight: "bold"
				}
			}
		},
		yAxisLeft: {
			//suggestedMax: 100,
			grid: {
				tickWidth: 0, drawBorder: false,
			},
			ticks: {
				font: {
					weight: "bold"
				}
			}
		}
	},
	locale: 'fr'
}
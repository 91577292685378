import logo from './images/logo.svg';
import mobileLogo from './images/mobile-logo.svg';
import logoText from './images/logo-text.svg';
import iconLeaf from './images/icon-leaf.svg';
import iconAdd from './images/icon-add.svg';
import iconMyAcc from './images/icon-my-acc.svg';
import iconLogout from './images/icon-logout.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
  useHistory
} from "react-router-dom";
import { useState } from 'react';
import Greening from './components/Greening/Greening.lazy';
import Badget from './components/Badget/Badget.lazy';
import MyWidgets from './components/MyWidgets/MyWidgets';
import MembersList from './components/Members/MembersList';
import EmailValidation from './components/validation/EmailValidation';
import { useCookies } from 'react-cookie';
import Logout from './components/logout/Logout';
import Mobile from './components/mobile/Mobile';
import PlanSelection from './components/PlanSelection/PlanSelection';
import CancelPlan from './components/CancelPlan/CancelPlan';
import { isMobile } from 'react-device-detect';
import { useResize } from './utils/Resize';
import { PlanNotification } from './components/PlanNotification/PlanNotificcation';
import Profile from './components/Profile/Profile';

function App(props: any): JSX.Element {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(['validatedEmails']);
  const [menuActive, setMenu] = useState<boolean>(false);

  const handleLink = (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  return (
    <Router>
      <div className="root-container">

        <div className='navWrapper'>
          <nav>
            {!(useResize() < 800) && <div className="menu-logo">
              <a href="https://greeningweb.com" target="_blank"><img className='menu-logo-img' src={logo} alt='' /><img className='menu-full-logo-img' src={logoText} alt='' /></a>
            </div>}
            <div className="menu-nav">
              <ul>
                {!menuActive &&
                  <li>
                    <NavLink
                      to="/home"
                      activeClassName="selected"
                      onClick={(event: any) => { return menuActive ? handleLink(event) : null; }}>
                      <div className="menu-item-icon"><img src={iconAdd} alt='' /></div>
                      <div className='menuName'>Add Badge</div>
                    </NavLink >
                  </li>
                }
                <li>
                  <NavLink to="/members" activeClassName="selected">
                    <div className="menu-item-icon"><img src={iconLeaf} alt='' /></div>
                    <div className='menuName'>Members</div>
                  </NavLink >
                </li>
                <li>
                  <NavLink to="/myWidgets" activeClassName="selected">
                    <div className="menu-item-icon"><img src={iconMyAcc} alt='' /></div>
                    <div className='menuName'>My Badges</div>
                  </NavLink >
                </li>
              </ul>
            </div>
            {!(useResize() < 800) && <div className="menu-footer">
              <ul>
                {cookies.validatedEmails &&
                  <li>
                    <NavLink
                      to="/logout"
                      activeClassName="selected">
                      <div className="menu-item-icon"><img src={iconLogout} alt='' /></div>
                      <div className='menuName'>Log Out</div>
                    </NavLink >
                  </li>
                }
              </ul>
            </div>}
          </nav>
        </div>
        <div className="content-container">
          {(useResize() < 800) &&
            <>
              <div className="menu-logo">
                <a href="https://greeningweb.com" target="_blank"><img className='menu-logo-img' src={mobileLogo} alt='' /><img className='menu-full-logo-img' src={logoText} alt='' /></a>
              </div>
              <div className="menu-footer">

                {cookies.validatedEmails &&

                  <NavLink
                    to="/logout"
                    activeClassName="selected">
                    <div className="logoutButton"><img src={iconLogout} alt='' /></div>

                  </NavLink >

                }

              </div>
            </>
          }
          <Switch>
            <Route path="/home">
              <Greening></Greening>
            </Route>
            <Route path="/badget">
              <Badget></Badget>
            </Route>
            <Route path="/members">
              <MembersList></MembersList>
            </Route>
            <Route path="/myWidgets">
              <MyWidgets></MyWidgets>
            </Route>
            <Route path="/emailValidation">
              <EmailValidation></EmailValidation>
            </Route>
            <Route path="/logout">
              <Logout></Logout>
            </Route>
            <Route path="/planSelection">
              <PlanSelection></PlanSelection>
            </Route>
            <Route path="/cancelPlan">
              <CancelPlan></CancelPlan>
            </Route>
            <Route path="/profile/:id">
              <Profile></Profile>
            </Route>
            <Route path="/planNotification">
              <PlanNotification></PlanNotification>
            </Route>
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <Redirect to="/home" />
                )
              }}
            />
          </Switch>
        </div>
      </div>
    </Router >
  );
}

export default App;

import { FC, useState } from 'react';
import chevronDownIcon from '../../images/icon-chevron-down.svg';
import { Popover } from 'react-tiny-popover';
import infoIcon from "../../images/icon-info-circle.svg";
import { getCO2Grams, getEnergyKwh, getEnergyWh, getLedLightMinutes, getTrees } from '../../utils/ConsumptionUtils';
import style from './ConsumptionPanel.module.css';
import styleMobile from './MobileConsumptionPanel.module.css';
import { isMobile } from 'react-device-detect';
import { useResize } from '../../utils/Resize';
interface ConsumptionPanelProps {
    websiteData: any
    doublePanel?: boolean
    open?: boolean
}

const ConsumptionPanel: FC<ConsumptionPanelProps> = (props) => {
    const [showDetails, setShowDetails] = useState<boolean>(props.open || false);
    const [perYearInfo, setPerYearInfo] = useState<boolean>(false);
    const screenWidth = useResize();

    return (
        <>
            <div className={props.doublePanel ? `container-inner no-margin` : `content-container-inner `} style={(screenWidth < 800) ? { marginBottom: '10px' } : {}} onClick={() => props.doublePanel ? '' : setShowDetails(!showDetails)}>
                {!props.doublePanel &&
                    <div className='data-panel-header' >
                        <a className="title" href={props.websiteData.url} target='_blank'><img height="16" width="16" src={`http://www.google.com/s2/favicons?domain=${props.websiteData.url}`} style={{ marginRight: '10px' }} /> {props.websiteData.url.replace(/^https?\:\/\//i, "").substring(0, screenWidth / 12)}</a>
                        <div className='expandCollapse'>
                            <img className={(screenWidth > 800) ? `${showDetails ? style.collapseButton : style.expandButton}` : `${showDetails ? styleMobile.collapseButton : styleMobile.expandButton}`}
                                aria-label="Expand"
                                src={chevronDownIcon} />
                        </div>
                    </div>
                }
                {showDetails &&
                    <div className={(screenWidth > 800) ? 'flexDisplay m-t-20' : 'flexDisplay m-t-15'} style={(screenWidth < 800) ? { marginTop: '15px' } : {}}>
                        <div className={`${props.doublePanel ? 'content-container-inner' : ''} consumption-container`}>
                            <label className="consumption-container-title">single visit</label>
                            <hr />
                            <div className='consumption-data-container'>
                                <p className='consumption-data'>⚡ <br />{getEnergyWh(props.websiteData)} WH</p>
                                <p className='consumption-data-desc'>is used every time<br /> someone visits this website</p>
                            </div>
                            <div className='consumption-data-container'>
                                <p className='consumption-data'>☁️ <br /> {getCO2Grams(props.websiteData)} G OF CO<sub>2</sub></p>
                                <p className='consumption-data-desc'>is produced every time  <br />someone visits this website</p>
                            </div>
                            <div className='consumption-data-container'>
                                <p className='consumption-data'>💡<br /> {getLedLightMinutes(props.websiteData)} MINS OF LED</p>
                                <p className='consumption-data-desc'>is equal to single page load</p>
                            </div>
                        </div>
                        <div className={`${props.doublePanel ? 'content-container-inner' : ''} consumption-container`}>
                            <label className="consumption-container-title">
                                per year
                                <Popover
                                    isOpen={perYearInfo}
                                    positions={[(screenWidth > 800) ? 'right' : 'left']}
                                    padding={10}
                                    reposition={false}
                                    content={<div>About 120,000 page visits</div>}
                                    containerClassName={`react-tiny-popover-container`}>
                                    <span onMouseEnter={() => setPerYearInfo(true)} onMouseLeave={() => setPerYearInfo(false)} className="info-icon font-inherit">
                                        <img src={infoIcon} alt='' />
                                    </span>
                                </Popover>
                            </label>
                            <hr />
                            <div className='consumption-data-container'>
                                <p className='consumption-data'>⚡ <br />{getEnergyKwh(props.websiteData, true)} KWH</p>
                                <p className='consumption-data-desc'>is used over the year <br /> by visiting this {(screenWidth < 420) ? <br /> : <></>} website</p>
                            </div>
                            <div className='consumption-data-container'>
                                <p className='consumption-data'>☁️ <br /> {(getCO2Grams(props.websiteData, true) / 1000).toFixed(4)} KG OF CO<sub>2</sub></p>
                                <p className='consumption-data-desc'>is produced over the year <br /> by visiting this website</p>
                            </div>
                            <div className='consumption-data-container'>
                                <p className='consumption-data'>🌳 <br /> {getTrees(props.websiteData, true)} TREES</p>
                                <p className='consumption-data-desc'>are needed to absorb this amount <br /> of emitted carbon</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
};

export default ConsumptionPanel;

import { Link } from "react-router-dom";

const Message = ({ message, type, hasLink, message2 }: { message: string, type: MessageType, hasLink?: any, message2?: string }) => {
    return (
        <div className={`content-container-inner ${type === MessageType.BLOCKER ? 'invalid' : ''}`}>
            <p >{`${getMessageIconByType(type)} `.trim()} {message} {(hasLink != undefined) && <a style={{ textDecoration: 'underline', font: 'normal normal normal 14px/20px Roboto' }} onClick={hasLink}> {hasLink && 'Upgrade the plan.'} </a>}{message2}</p>
        </div>
    );
}

const getMessageIconByType = (type: MessageType) => {
    switch (type) {
        case MessageType.INFO:
            return 'ℹ️';
        case MessageType.SUCCESS:
            return '🎉';
        case MessageType.WARNING:
            return '⚠️';
        case MessageType.WILL_BLOCK:
            return '❗️';
        case MessageType.BLOCKER:
            return '⛔';
        default:
            return '';
    }
}

export enum MessageType {
    GUIDANCE,
    INFO,
    SUCCESS,
    WARNING,
    WILL_BLOCK,
    BLOCKER,
}
export default Message;
import planContainerStyles from './PlanContainer.module.css';
import planContainerMobileStyles from './PlanContainerMobile.module.css';
import greenCheckedIcon from '../../images/icon-green-checked.svg';
import greyCheckedIcon from '../../images/icon-grey-checked.svg';
import greenLeafIcon from '../../images/icon-green-leaf.svg';
import greyLeafIcon from '../../images/icon-grey-leaf.svg';
import ImageSelector from '../ImageSelector/ImageSelector';
import DiscountMessage from '../DiscountMessage/DiscountMessage';
import { useResize } from '../../utils/Resize';

interface PlanContainerProps {
    planName: string,
    perks: any,
    price?: any,
    disabled?: any,
    onSale?: any,
    planPaymentInterval: string,
    discountPrice?: number,
    onClick?: Function,
    selected?: boolean,
    isLoading: boolean,
}

const defaultProps: PlanContainerProps = {
    planName: '<Enter Plan Name>',
    perks: ['<Enter Perk 1>', '<Enter Perk 2>'],
    price: null,
    disabled: false,
    onSale: false,
    planPaymentInterval: '<Enter plan payment interval>',
    discountPrice: 0,
    onClick: () => { },
    selected: false,
    isLoading: false,
}

const PlanContainer: React.FunctionComponent<PlanContainerProps> = (props: any) => {
    const priceColorStyle = props.onSale ? planContainerStyles.redColor : '';
    const shownMainPrice = props.onSale ? props.discountPrice : props.price;
    const shownDiscountPrice = props.onSale ? '$' + props.price : '';
    const screenWidth = useResize();
    return (
        <div className={`content-container-inner ${(screenWidth > 800) ? planContainerStyles.planContainer : planContainerMobileStyles.planContainer} ${props.disabled ? planContainerStyles.disabledPlan : planContainerStyles.selectedPlan}`}>
            <div className={(screenWidth > 800) ? planContainerStyles.leftContainer : planContainerMobileStyles.topContainer}>
                <p>
                    <ImageSelector test={props.disabled} ifTrueImg={greyLeafIcon} ifFalseImg={greenLeafIcon} />
                    <span className={(screenWidth > 800) ? planContainerStyles.textNearIcon : planContainerMobileStyles.textNearIcon}>{(screenWidth < 800) ? <br /> : ''}{props.planName}</span>
                </p>
                <div style={(screenWidth > 800) ? {} : { textAlign: 'left', paddingLeft: '10px' }}>
                    <p className={planContainerStyles.topCheckedLine}>
                        <ImageSelector test={props.disabled} ifTrueImg={greyCheckedIcon} ifFalseImg={greenCheckedIcon} /> {props.perks[0]}
                    </p>
                    <p>
                        <ImageSelector test={props.disabled} ifTrueImg={greyCheckedIcon} ifFalseImg={greenCheckedIcon} /> {props.perks[1]}
                    </p>
                </div>
            </div>
            <div className={(screenWidth > 800) ? planContainerStyles.rightContainer : planContainerMobileStyles.bottomContainer}>
                <p className={(screenWidth > 800) ? planContainerStyles.priceText : (props.price !== null) ? planContainerMobileStyles.priceText : ''} >
                    {props.price !== null && <>
                        <span className={`${planContainerStyles.price} ${priceColorStyle}`}>${shownMainPrice}</span> <span className={planContainerStyles.pricingInterval}>/ <span className={planContainerStyles.previousPrice}>{shownDiscountPrice}</span> {props.planPaymentInterval}</span></>}
                </p>
                {props.price === null && props.onSale &&
                    <DiscountMessage message={'save 25%'} className={(screenWidth > 800) ? planContainerStyles.EmptyPlanMessage : planContainerMobileStyles.EmptyPlanMessage} />}
                <br />

                <button className={`${props.disabled ? '' : 'greenHoverBorder'} ${(screenWidth > 800) ? planContainerStyles.selectPlanButton : planContainerMobileStyles.selectPlanButton} ${props.selected ? planContainerStyles.selectedButton : ''}`} onClick={() => { !props.selected && props.onClick() }} disabled={props.disabled}>

                    {!props.isLoading &&
                        (props.planName === 'enterprice' ?
                            'contact us' : props.disabled ?
                                'soon' : props.selected ?
                                    'current plan' : 'select')}
                    {props.isLoading && <div className='loadingIndicator dot-flashing inline'></div>}
                </button>
            </div>
        </div >
    );
}

PlanContainer.defaultProps = defaultProps;

export default PlanContainer;
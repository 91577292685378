import React, { useEffect, useState } from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useResize } from './utils/Resize';
import "./App.css";
import './AppMobile.css';

//const MobileVar = React.lazy(() => import('./AppCSS/appMobileTheme'));
//const AppVar = React.lazy(() => import('./AppCSS/appTheme'));

/*const sleep = () => new Promise(resolve => {
  setTimeout(resolve, 3000)
})

const ThemeSelector = ({ children }: any) => {
  const width = useResize()
  const useLoadCss = () => {
    if (width < 800) {
      //return <MobileVar />
    } else {

      //return <AppVar />
    }
  }

  return (
    <>
      <React.Suspense fallback={<></>}>
        {
          useLoadCss()
        }
      </React.Suspense>
      {children}
    </>
  )
}*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { FC, useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import snippet from '../../images/snippet-leaf.svg';
import { enrexApi } from "../../services/EnrexApiService";
import Select from 'react-select';
interface ISnippet {
	account?: any
}
export const Snippet: FC<ISnippet> = ({ account }) => {
	const sizes = [{ value: 14, label: "14px" }, { value: 16, label: "16px" }, { value: 18, label: "18px" }, { value: 20, label: "20px" }]
	const history = useHistory();

	const [selectedSize, setSelectedSize] = useState<any>()
	const [background, setBackground] = useState<string>()

	useEffect(() => {
		var defaultSize = 14;
		sizes.forEach((size) => {
			if (size.value == account?.badgetSettings?.size) {
				defaultSize = size.value;
			}
		});
		setSelectedSize({ value: defaultSize, label: `${defaultSize}px` })
		if (account?.badgetSettings?.background) { setBackground(account.badgetSettings?.background); }
		else { setBackground('light'); }
	}, [account]);
	const getBackgroundMode = (background: string, inverted: boolean) => {
		if (background === 'light') {
			if (inverted) { return ('#49596B') }
			else { return ('#F9F9F9') }
		} else {
			if (inverted) { return ('#F9F9F9') }
			else { return ('#49596B') }
		}
	}
	const parseSelectedSize = (size: any, background?: string) => {
		console.log(size, " ", background)
		setSelectedSize(size);
		enrexApi.get("/updateBadgetSettings", {
			params: {
				id: account.id,
				type: 'snippet',
				size: size.value,
				background: background,
			}
		})
	}

	return (<>
		<label className="content-container-inner-label">snippet preview</label>
		{background &&
			<div className={'content-container-inner'} style={{ textAlign: 'center', background: getBackgroundMode(background, false) }}>
				<p style={{ font: ` normal normal normal ${selectedSize.value}px/23px Roboto`, color: getBackgroundMode(background, true) }}>
					This website is
					<img src={snippet} style={{ top: '4px', height: `${selectedSize.value}px`, position: `relative` }}>
					</img>
					<a style={{ color: '#39C1BC', textDecoration: 'none', fontSize: selectedSize.value }} onClick={() => { history.push(`/profile/${account.id}`) }}> GREEN</a>
				</p>
			</div>}
		{account && <>
			<label className="content-container-inner-label">customise your badge</label>
			<div className={'content-container-inner'}>
				<p>size</p>
				<div style={{ marginBottom: "10px", marginTop: "10px" }}>
					<Select options={sizes}
						placeholder="Asset type"
						className={``}
						classNamePrefix="c-select"
						onChange={(size) => { parseSelectedSize(size); }}
						value={selectedSize}
						isSearchable={false}
					/>
				</div>
				<p>background prevew color</p>
				<div className='checkbuttonContainer m-b-30'>
					<input type='radio' checked={background === 'light'} onChange={(e) => {
						setBackground('light'); parseSelectedSize(selectedSize, 'light');
					}} id="leftRadio" />
					<label htmlFor="leftRadio" className='checkBoxText'>Light</label>
					<input type='radio' checked={background === 'dark'} onChange={(e) => {
						setBackground('dark'); parseSelectedSize(selectedSize, 'dark');
					}} id="rightRadio" />
					<label htmlFor="rightRadio" className='checkBoxText'>Dark</label>
				</div>
			</div>
		</>}
	</>
	)
}
import planSelectionStyles from './PlanSelection.module.css';
import PlanContainer from '../PlanContainer/PlanContainer';
import { useHistory } from 'react-router-dom';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import DiscountMessage from '../DiscountMessage/DiscountMessage';
import iconBackArrow from '../../images/icon-back-arrow.svg';
import { FC, useEffect, useState } from 'react';
import config from '../../config';
import { enrexApi } from '../../services/EnrexApiService';
import { textChangeRangeIsUnchanged } from 'typescript';
import { useResize } from '../../utils/Resize';
import Select from 'react-select';
import informationButton from "../../images/icon-error.svg";

interface PlanSelectionParams {
    paramAccount?: any
}

const PlanSelection: FC<PlanSelectionParams> = (params) => {
    const history = useHistory();
    const [isYearPlan, setIsYearPlan] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<any>()
    const [plan, setPlan] = useState<any>("Freemium")
    const [loadingPlan, setLoadingPlan] = useState<string>('')
    const [newCreated, setNewCreated] = useState<boolean>(false)
    const [accounts, setAccounts] = useState<any>()

    const screenWidth = useResize();

    const storedEmail = localStorage.getItem('email');
    const storedId = localStorage.getItem('id');
    let selectedAccountEmail: any
    let selectedAccountId: any

    let account = history.location.state as any;
    if (account) {
        if (account.selectedAccount) {
            selectedAccountEmail = account.selectedAccount.email;
            selectedAccountId = account.selectedAccount.id;
            localStorage.setItem('email', selectedAccountEmail);
            localStorage.setItem('id', selectedAccountId);
        }
    } else {
        selectedAccountEmail = storedEmail;
        selectedAccountId = storedId;
    }
    if (params.paramAccount) {
        selectedAccountEmail = params.paramAccount.email;
        selectedAccountId = params.paramAccount.id;
    }

    //console.log(selectedAccountEmail, " ", selectedAccountId);
    useEffect(() => {
        if (params.paramAccount) {
            selectedAccountEmail = params.paramAccount.email;
            selectedAccountId = params.paramAccount.id;
            parseSelected(params.paramAccount);
        }
    }, [params]);

    useEffect(() => {
        if (!selectedAccount) {
            getAccount(selectedAccountId);
        }
    }, []);

    const setInitialIsYearPlan = (selectedAccount: any) => {
        if (selectedAccount.subscription) {
            if (selectedAccount.subscription.period === "year") { setIsYearPlan(true) }
            else { setIsYearPlan(false) }
        } else {
            setIsYearPlan(false)
        }
    }

    const getAccount = async (id: string) => {
        try {
            const result = await enrexApi.get("/accountInfo", {
                params: {
                    id
                }
            })
            if (result.data.newCreated) {
                setNewCreated(true);
            } else {
                setNewCreated(false);
            }
            if (result.data.newCreated) {
                getAccountsByEmail(result.data.email)

                result.data.label = <p className={planSelectionStyles.labelHolder}>{result.data.offsetCounter > 0 ? '✅' : '⛔'} {result.data.url} <span className={`${planSelectionStyles.rightValue} ${planSelectionStyles.infoText}`}>{(screenWidth > 800) && (result.data.offsetCounter > 0 ? 'widget added' : 'widget not added')}</span></p>;
                result.data.value = result.data.url;
            }

            setSelectedAccount(result.data);
            setPlan(result.data.subscription.planName);
            setInitialIsYearPlan(result.data);

        } catch (e: any) {
            console.error(e)
        }
    }

    const redirectToBuy = (amount: string, YearlyLink: string, MonthlyLink: string) => {
        let passParams = "?prefilled_email=" + selectedAccountEmail + "&client_reference_id=" + selectedAccountId;
        if (isYearPlan) {
            if (selectedAccount.subscription) {
                if (selectedAccount.subscription.subscriptionId) {
                    //updatePlan(selectedAccount.id, amount + "kYearly")
                    history.push({
                        pathname: '/planNotification',
                        state: {
                            selectedAccountId: selectedAccountId,
                            planName: amount + "kYearly"
                        }
                    })
                }
                else {
                    (plan === amount + "kYearly") ? console.log() : window.location.assign(YearlyLink + passParams)
                }
            }
            else { (plan === amount + "kYearly") ? console.log() : window.location.assign(YearlyLink + passParams) }
        } else {
            if (selectedAccount.subscription) {
                if (selectedAccount.subscription.subscriptionId) {
                    //updatePlan(selectedAccount.id, amount + "kMonthly")
                    history.push({
                        pathname: '/planNotification',
                        state: {
                            selectedAccountId: selectedAccountId,
                            planName: amount + "kMonthly"
                        }
                    })
                }
                else {
                    (plan === amount + "kMonthly") ? console.log() : window.location.assign(MonthlyLink + passParams)
                }
            }
            else { (plan === amount + "kMonthly") ? console.log() : window.location.assign(MonthlyLink + passParams) }
        }
    }

    const getAccountsByEmail = async (email: string) => {
        const result = await enrexApi.get('/accountsByEmail', {
            params: {
                email,
            },
        });

        const accountsResult = result.data.map((account: any) => {
            account.label = <p className={planSelectionStyles.labelHolder}>{account.offsetCounter > 0 ? '✅' : '⛔'} {account.url} <span className={`${planSelectionStyles.rightValue} ${planSelectionStyles.infoText}`}>{(screenWidth > 800) && (account.offsetCounter > 0 ? 'widget added' : 'widget not added')}</span></p>;
            account.value = account.url;
            return account;
        })
        setAccounts(accountsResult);
    }

    const parseSelected = (account: any) => {
        if (selectedAccount?.id !== account.id) {
            localStorage.setItem('email', selectedAccountEmail);
            localStorage.setItem('id', selectedAccountId);

            setPlan(account.subscription ? account.subscription.planName : "Freemium");
            setSelectedAccount(account);
            setInitialIsYearPlan(account);
            selectedAccountId = account.id;

            history.push({
                pathname: '/myWidgets',
                state: {
                    selectedAccountId: selectedAccountId
                }
            })
        }
    }

    const handleBackButton = async () => {
        if (newCreated) {
            console.log(selectedAccount)
            await enrexApi.get("/updateAccount", {
                params: {
                    id: selectedAccount.id,
                    newCreated: false
                }
            })
        }
        if (window.location.pathname === '/myWidgets') {
            window.location.reload();
        } else {
            history.push({
                pathname: '/myWidgets',
                state: {
                    selectedAccountId: selectedAccountId
                }
            })
        }
    }

    return (
        <div className="dapp-container">
            {newCreated && !params.paramAccount &&
                <div className={(screenWidth > 800) ? "content-container-inner" : planSelectionStyles.webSearch}>
                    <Select options={accounts}
                        placeholder="Select Website"
                        className={``}
                        classNamePrefix="c-select"
                        onChange={(account) => { parseSelected(account); }}
                        value={selectedAccount}
                        isSearchable={false}
                    />
                </div>
            }
            {selectedAccount?.subscription?.subscriptionStatus?.status === 'pendingUpdate' &&
                <div className={`content-container-inner ${planSelectionStyles.redBoarder}`}>
                    <p className='note'>
                        <p>⛔️ </p>
                        <p>Plan upgrade is in progress, payment pending. Please check if your funds are sufficient, or if you want to change a payment method please contact our support.</p>
                    </p>
                </div>
            }

            {(screenWidth > 800) && <label className="content-container-inner-label">make your user activity on your website co<sub>2</sub> neutral</label>}
            <label className="content-container-inner-label">choose a <span className={`accent-color ${planSelectionStyles.labelText}`}>green browsing plan</span> that works for you</label>
            <div className={`content-container-inner ${(screenWidth < 800) ? planSelectionStyles.mobileContent : ''}`}>
                <div className={planSelectionStyles.toggleSwitch}>
                    <span className={(screenWidth > 800) ? planSelectionStyles.toggleOptions : planSelectionStyles.mobileToggleOptions} >Monthly</span>
                    <ToggleSwitch initial={isYearPlan} setValue={setIsYearPlan} />
                    <span className={(screenWidth > 800) ? planSelectionStyles.toggleOptions : planSelectionStyles.mobileToggleOptions} >Yearly</span>
                    {!isYearPlan && <DiscountMessage message={(screenWidth > 800) ? 'save 25%' : '-25%'} className={planSelectionStyles.discountMessage} />}
                </div>
                <div className={(screenWidth < 800) ? planSelectionStyles.scroller : ""}>
                    <PlanContainer planName={'freemium'} perks={['0-10k visits/month', 'Group certificate']} isLoading={false} price={0} disabled={false} selected={plan === "Freemium" ? true : false} onSale={false} planPaymentInterval={isYearPlan ? 'year' : 'month'} onClick={() => {
                        (plan === "Freemium") ? console.log() : history.push({
                            pathname: '/cancelPlan',
                            state: {
                                selectedAccountId: selectedAccountId
                            }
                        })
                    }} />
                    <PlanContainer planName={'starter'} perks={['10-100k visits/month', 'Personalized certificate']} isLoading={loadingPlan == "100kYearly" || loadingPlan == "100kMonthly"} price={19} disabled={false} selected={(isYearPlan && plan === "100kYearly") ? true : ((!isYearPlan && plan === "100kMonthly") ? true : false)} onSale={isYearPlan} planPaymentInterval={isYearPlan ? 'year' : 'month'} discountPrice={171} onClick={() => { redirectToBuy("100", config.stripeUrls.Yearly100, config.stripeUrls.Monthly100) }} />
                    <PlanContainer planName={'advanced'} perks={['100-500k visits/month', 'Personalized certificate']} isLoading={loadingPlan == "500kYearly" || loadingPlan == "500kMonthly"} price={79} disabled={false} selected={(isYearPlan && plan === "500kYearly") ? true : ((!isYearPlan && plan === "500kMonthly") ? true : false)} onSale={isYearPlan} planPaymentInterval={isYearPlan ? 'year' : 'month'} discountPrice={711} onClick={() => { redirectToBuy("500", config.stripeUrls.Yearly500, config.stripeUrls.Monthly500) }} />
                    <PlanContainer planName={'enterprice'} perks={['500k+ visits/month', 'Personalized certificate']} isLoading={false} disabled={false} onSale={isYearPlan} planPaymentInterval={isYearPlan ? 'year' : 'month'} onClick={() => { window.location.assign(config.stripeUrls.enterprice) }} />
                </div>
            </div>
            {<>
                <button className={`greenHoverBorder ${newCreated ? "" : planSelectionStyles.backButton}`} onClick={() => {
                    handleBackButton()
                }}>{newCreated ? "Continue" : <img className={planSelectionStyles.backArrow} src={iconBackArrow} />}</button>
                {(newCreated == false) && <button className={`${plan === "Freemium" ? '' : 'greenHoverBorder'} ${planSelectionStyles.cancelButton}`} disabled={plan === "Freemium" ? true : false} onClick={() => {
                    history.push({
                        pathname: '/cancelPlan',
                        state: {
                            selectedAccountId: selectedAccountId
                        }
                    })
                }
                }> Cancel Plan</button>}
            </>
            }
        </div>
    );
}

export default PlanSelection;

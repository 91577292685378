export const mutatePlanText = (planName: String, visitsLimit: number) => {
	let plan = planName === "Freemium" ?
		planName : planName.includes("Yearly") ?
			"Yearly" : "Monthly";

	if (plan != "Freemium") {
		let type;
		switch (visitsLimit) {
			case 10000:
				type = ' '
				return plan + type;
			case 100000:
				type = ' starter'
				return plan + type;
			case 500000:
				type = ' advanced'
				return plan + type;
			default:
				type = ' custom'
				return plan + type;
		}
	} else {
		return plan;
	}
}

export const mutatePlanName = (planName: string) => {
	let plan = planName === "Freemium" ?
		' Freemium (10k  monthly views)' : planName.includes("Yearly") ?
			" yearly bill)" : " monthly bill)";

	if (plan != " Freemium (10k  monthly views)") {
		let type: string;
		if (planName.includes('100k')) {
			type = ' Starter (100k monthly views,'
		} else if (planName.includes('500k')) {
			type = ' Advanced (500k monthly views,'
		} else {
			type = ' Custom'
		}
		return type + plan;
	} else {
		return plan;
	}
}
import { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import config from '../../config';
import { enrexApi } from '../../services/EnrexApiService';
import { getCO2Grams, getEnergyKwh, getEnergyWh } from '../../utils/ConsumptionUtils';
import LoadingButton from '../LoadingButton/LoadingButton';
import { badgetPreview } from '../Badget/BadgetPreview';
import styles from './MyWidgets.module.css';
import moment from 'moment';
import { Notification } from '../Notification/Notification';
import { PlanOverview } from '../PlanOverview/PlanOverview';
import { mutatePlanText } from '../../utils/MutateSubscriptionName';
import { useResize } from '../../utils/Resize';
import mobileStyles from './MyWidgetsMobile.module.css';
import PlanSelection from '../PlanSelection/PlanSelection';
import Message, { MessageType } from '../message/Message';
import { getLimitExceededMessage, getLimitExceededMessage2, getPaymentMissingMessage, getPaymentSuccess, getWidgetActivatedMessage, getWidgetDeactivatedMessage, getWidgetInactiveMessage } from '../../utils/NotificationUtils';
import { EnergyChart } from '../EnergyChart/EnergyChart';
import { Badge } from '../Badge/Badge';
import { Snippet } from '../Snippet/Snippet';
interface MyWidgetsProps {
}

const MyWidgets: FC<MyWidgetsProps> = (props) => {
    const history = useHistory();
    const languages = [{ value: "en", label: "English" }, { value: "de", label: "German" }, { value: "sk", label: "Slovak" }, { value: "pt", label: "Portuguese" }, { value: "es", label: "Spanish" }, { value: "fr", label: "French" }, { value: "it", label: "Italian" }, { value: "ar", label: "Arabic" }, { value: "lt", label: "Lithuanian" }];
    const badgetTypes = [{ value: "widget", label: "widget" }, { value: "mark", label: "mark" }, { value: "snippet", label: "snippet" }]
    const [cookies, setCookie, removeCookie] = useCookies(['validatedEmails']);
    const [accounts, setAccounts] = useState<any>()
    const [selectedAccount, setSelectedAccount] = useState<any>()

    const [rightWidget, setRightWidget] = useState<boolean>()
    const [testSuccessState, setTestSuccessState] = useState<string>('')
    const [testing, isTesting] = useState<boolean>(false)
    const [progressWidth, setProgressWidth] = useState<any>()
    const [monthlyCounter, setMonthlyCounter] = useState<number>(0)

    const [selectedLanguage, setSelectedLanguage] = useState<any>()
    const [selectedBadget, setSelectedBadget] = useState<any>()
    const screenWidth = useResize();

    useEffect(() => {
        if (cookies.validatedEmails) {
            getData(cookies.validatedEmails.email)
        } else {
            history.push({
                pathname: '/emailValidation',
            });
        }
    }, []);

    useEffect(() => {
        if (!cookies.validatedEmails) {
            history.push({
                pathname: '/emailValidation',
            });
        }
    }, [cookies.validatedEmails]);

    useEffect(() => {
        setTestSuccessState('');
        if (selectedAccount && selectedLanguage) {
            if (document.getElementById('badget'))
                badgetPreview(selectedAccount.url, selectedAccount.kwh, selectedAccount.co2, rightWidget ? 'right' : 'left', selectedLanguage.value, (screenWidth < 800));
        }
    }, [selectedAccount, selectedLanguage, screenWidth, selectedBadget, rightWidget]);

    const getAccountsByEmail = async (email: string) => {
        const result = await enrexApi.get('/accountsByEmail', {
            params: {
                email,
            },
        });
        return result.data;
    }

    const getData = async (email: string) => {
        const data = await getAccountsByEmail(email);
        const accountsResult = data.map((account: any) => {
            account.label = <p className={styles.labelHolder}>{getLabelInfo(account).emoji} {account.url} <span className={`${styles.rightValue} ${styles.infoText}`}>{getLabelInfo(account).rightText}</span></p>;
            account.value = account.url;
            return account;
        })
        setAccounts(accountsResult);
        let passedAccount = history.location.state as any;
        if (passedAccount?.selectedAccountId) {
            let acc = accountsResult.filter((account: any) => account.id === passedAccount.selectedAccountId)[0]
            parseSelected(acc);
        } else {
            parseSelected(accountsResult[0]);
        }
    }

    const getLabelInfo = (account: any) => {
        let emoji;
        let rightText;
        if ((!account.activeWidget && account.subscription?.subscriptionStatus.status === 'locked')) { emoji = '⛔'; rightText = "blockers exist" }
        else if ((account.subscription?.subscriptionStatus?.status === 'pendingUpdate' && account.subscription?.paymentStatus?.status === 'failed') || (account.triggers?.Inactive24H_Web)) { emoji = '❗️'; rightText = "blockers approaching" }
        else if ((account.offsetCounter > 0)) { emoji = '✅'; rightText = "badge added" }
        else { emoji = '⛔'; rightText = "badge is not added" }
        return { emoji, rightText }
    }

    const transformLabel = () => {
        if (accounts) {

            if (screenWidth < 800) {
                accounts.forEach((account: any) => {
                    account.label = <p className={styles.labelHolder}>{getLabelInfo(account).emoji} {account.url}</p>;
                })
            }
            if (screenWidth > 800) {
                accounts.forEach((account: any) => {
                    account.label = <p className={styles.labelHolder}>{getLabelInfo(account).emoji} {account.url} <span className={`${styles.rightValue} ${styles.infoText}`}>{getLabelInfo(account).rightText}</span></p>;
                })
            }
        }
    }

    window.addEventListener('resize', transformLabel);

    const parseSelected = async (value: any) => {

        setSelectedAccount(value);
        const result = await enrexApi.get('/currentMonthWebGreenCounter', {
            params: {
                id: value.id,
            },
        });
        setMonthlyCounter(result.data.counter)
        let widthPercentege = result.data.counter / (value.subscription ? value.subscription.visitsLimit : value.offsetLimit) * 100;
        if (widthPercentege > 100) {
            widthPercentege = 100;
        }
        if (!widthPercentege) {
            widthPercentege = 0;
        }
        if (widthPercentege < 1) {
            widthPercentege = 1;
        }
        setProgressWidth(widthPercentege);
        setInitialLanguage(value.language);
        console.log(value.badgetSettings?.side)
        if (value.badgetSettings?.side) { setRightWidget(value.badgetSettings?.side == 'right') } else {
            setRightWidget(false)
        }
        if (value.badgetSettings?.type) { setSelectedBadget({ value: value.badgetSettings.type, label: value.badgetSettings.type }); }
        else { setSelectedBadget({ value: "widget", label: "widget" }); }
        if (value.subscription?.newPayment) { updateIfNewPayment(value); }
        if (value?.showWidgetActivatedNotification) { updateShowWidgetActivatedNotificationToFalse(value); }
    }

    const testWidget = async () => {
        isTesting(true)
        setTestSuccessState('');
        const selectedUrl = selectedAccount.url;
        const lastCount = selectedAccount.offsetCounter;
        window.open(selectedUrl)
        var iterations = 0;
        const interval = setInterval(async () => {
            iterations++;
            if (iterations > 10) {
                isTesting(false)
                setTestSuccessState('FAILED');
                clearInterval(interval);
                return;
            }
            const accounts = await getAccountsByEmail(cookies.validatedEmails.email);
            const account = accounts.find((account: any) => account.url === selectedUrl);
            if (account && account.offsetCounter > lastCount) {
                setTestSuccessState('SUCCESS');
                isTesting(false)
                clearInterval(interval);
            }
        }, 1000)
    }

    const setInitialLanguage = (language: string) => {
        let lanCode = language ? language : "en";
        let result = languages.filter(languageOption => languageOption.value === lanCode)[0]
        setSelectedLanguage(result);
    }

    const parseSelectedLanguage = async (value: any) => {
        const result = await enrexApi.get('/updateAccount', {
            params: {
                id: selectedAccount.id,
                language: value.value
            },
        });
        setSelectedLanguage(value);
    }

    const delabelAccount = (account: any) => {
        account.label = '';
        return account;
    }

    const updateIfNewPayment = async (account: any) => {
        await enrexApi.get("/updateAccount", {
            params: {
                id: account.id,
                newPayment: false
            }
        })
    }

    const updateShowWidgetActivatedNotificationToFalse = async (account: any) => {
        await enrexApi.get("/updateAccount", {
            params: {
                id: account.id,
                showWidgetActivatedNotification: false
            }
        })
    }

    const parseSelectedSide = async (side?: string) => {
        setRightWidget(side == 'right');
        let newWidgetSettings = await enrexApi.get("/updateBadgetSettings", {
            params: {
                id: selectedAccount.id,
                type: "widget",
                side: side,
            }
        })
        selectedAccount.badgetSettings = newWidgetSettings.data.badgetSettings;
    }

    const parseSelectedBadget = async (badget: any) => {
        setSelectedBadget(badget);
        await enrexApi.get("/updateBadgetSettings", {
            params: {
                id: selectedAccount.id,
                type: badget.value,
            }
        })
    }

    const getMaxVisitors = () => {
        const visits = selectedAccount.energyLog.map((log: any) => {
            return log.offsetCounter;
        })
        const max = Math.max.apply(null, visits);
        return max;
    }

    return (
        <>
            {accounts &&
                <div className="dapp-container">
                    {(screenWidth > 800) && <label className="content-container-inner-label">make your user activity on your website co<sub>2</sub> neutral</label>}
                    <label className="content-container-inner-label">enrex green browsing offer for</label>
                    <div className={(screenWidth > 800) ? "content-container-inner" : mobileStyles.webSearch}>
                        <Select options={accounts}
                            placeholder="Select Website"
                            className={``}
                            classNamePrefix="c-select"
                            onChange={(account) => { parseSelected(account); }}
                            value={selectedAccount}
                            isSearchable={false}
                        />
                    </div>
                    {selectedAccount &&
                        <>
                            {selectedAccount.subscription?.subscriptionStatus.status === 'limitExceeded' &&
                                <Message type={MessageType.WILL_BLOCK} message={getLimitExceededMessage(selectedAccount)} hasLink={() => {
                                    history.push({
                                        pathname: '/planSelection',
                                        state: {
                                            selectedAccount: delabelAccount(selectedAccount)
                                        }
                                    })
                                }} message2={getLimitExceededMessage2(selectedAccount)} />
                            }
                            {selectedAccount.triggers?.Inactive24H_Web &&
                                <Message type={MessageType.WILL_BLOCK} message={getWidgetInactiveMessage(selectedAccount)} />
                            }
                            {selectedAccount.triggers?.PaymentIsMissing24h_Web &&
                                <Message type={MessageType.WILL_BLOCK} message={getPaymentMissingMessage(selectedAccount)} />
                            }
                            {!selectedAccount.activeWidget && selectedAccount.subscription?.subscriptionStatus.status === 'locked' &&
                                <Message type={MessageType.BLOCKER} message={getWidgetDeactivatedMessage(selectedAccount)} hasLink={selectedAccount.triggers.SubscriptionLimit_Web_14d ?
                                    () => {
                                        history.push({
                                            pathname: '/planSelection',
                                            state: {
                                                selectedAccount: delabelAccount(selectedAccount)
                                            }
                                        })
                                    } : false} />
                            }
                            {selectedAccount.subscription?.subscriptionStatus.status === 'pendingUpdate' && selectedAccount.subscription.paymentStatus.status === 'failed' &&
                                <Message type={MessageType.WILL_BLOCK} message={getPaymentMissingMessage(selectedAccount)} />
                            }
                            {selectedAccount?.subscription?.newPayment &&
                                <Message type={MessageType.SUCCESS} message={getPaymentSuccess(selectedAccount)} />
                            }
                            {selectedAccount?.showWidgetActivatedNotification && selectedAccount?.subscription?.newPayment &&
                                <Message type={MessageType.SUCCESS} message={getWidgetActivatedMessage(selectedAccount)} />
                            }
                            {!selectedAccount.newCreated && <div className="container-inner no-margin">
                                {selectedAccount.subscription ?
                                    <PlanOverview planName={selectedAccount.subscription.planName} visitsLimit={selectedAccount.subscription.visitsLimit} /> :
                                    <PlanOverview planName="Freemium" visitsLimit={10000} />
                                }
                                <div className='content-container-inner consumption-container'>
                                    <label className="consumption-container-title">consumption {(screenWidth > 800) ? 'overview' : ''}</label>
                                    <hr />
                                    <div className='consumption-data-container'>
                                        <p className='consumption-data'>☁️ {getCO2Grams(selectedAccount)} G OF CO<sub>2</sub><br /> ⚡ {getEnergyWh(selectedAccount)} WH</p>
                                        <p className='consumption-data-desc'>is produced every time someone visits your website</p>
                                    </div>
                                    <div className='consumption-data-container'>
                                        <p className='consumption-data'>☁️ {(getCO2Grams(selectedAccount, true) / 1000).toFixed(4)} KG OF CO<sub>2</sub><br />⚡ {getEnergyKwh(selectedAccount, true)} KWH</p>
                                        <p className='consumption-data-desc'>is produced over a year (~120,000 website visits)</p>
                                    </div>
                                </div>
                            </div>}
                            {selectedAccount.newCreated &&
                                <PlanSelection paramAccount={selectedAccount}></PlanSelection>
                            }

                            {!selectedAccount.newCreated && selectedAccount.offsetCounter > 0 &&
                                <>
                                    <label className="content-container-inner-label">current plan overview</label>
                                    <div>
                                        <div className={`content-container-inner center-vertical`} style={{ paddingTop: '0px' }}>
                                            <p className={(screenWidth < 800) ? mobileStyles.labelHolder : styles.labelHolder}><span className={(screenWidth < 800) ? mobileStyles.leftLabel : styles.leftLabel}>plan</span><span className={`${(screenWidth < 800) ? mobileStyles.rightValue : styles.rightValue} ${styles.accentColor}`}>{selectedAccount.subscription ? mutatePlanText(selectedAccount.subscription.planName, selectedAccount.subscription.visitsLimit) : "Freemium"}</span></p>
                                            <p className={(screenWidth < 800) ? mobileStyles.labelHolder : styles.labelHolder}><span className={(screenWidth < 800) ? mobileStyles.leftLabel : styles.leftLabel}>visits</span><span className={(screenWidth < 800) ? mobileStyles.rightValue : styles.rightValue}>{selectedAccount.subscription ? selectedAccount.subscription.visitsLimit : 10000} per month</span></p>
                                            <p className={(screenWidth < 800) ? mobileStyles.labelHolder : styles.labelHolder}><span className={(screenWidth < 800) ? mobileStyles.leftLabel : styles.leftLabel}>activation date</span><span className={(screenWidth < 800) ? mobileStyles.rightValue : styles.rightValue}>{moment(selectedAccount.date).format('YYYY/MM/DD')}</span></p>
                                            <p className={(screenWidth < 800) ? mobileStyles.labelHolder : styles.labelHolder}><span className={(screenWidth < 800) ? mobileStyles.leftLabel : styles.leftLabel}>refresh monthly visit date</span><span className={(screenWidth < 800) ? mobileStyles.rightValue : styles.rightValue}>{selectedAccount.subscription ? moment(selectedAccount.subscription.nextPeriodStartDate).format('YYYY/MM/DD') : moment().add(1, 'months').startOf('month').format('YYYY/MM/DD')}</span></p>
                                            <p className={styles.line}></p>
                                            <p className={(screenWidth < 800) ? mobileStyles.labelHolder : styles.labelHolder}><p className={(screenWidth < 800) ? mobileStyles.leftLabel : styles.leftLabel}>monthly visits overview</p></p>
                                            <div className={`center-horizontal center-vertical ${styles.labelHolder}`}>
                                                <div className='progressBarWrapper'><div className='progressBar'><div style={{ width: `${progressWidth}%` }} className='progressBar filled'></div></div></div>
                                            </div>
                                            <p className={`${styles.monthlyUserText}`}>Hooray, {monthlyCounter} users have already experienced green browsing on your website!</p>
                                        </div>
                                    </div>
                                </>
                            }

                            {!selectedAccount.newCreated && <><Notification selectedAccount={selectedAccount} monthlyCounter={monthlyCounter} />

                                <button className={`InverseGreenHoverBorder ${styles.planSelectionButton}`} onClick={() => {
                                    history.push({
                                        pathname: '/planSelection',
                                        state: {
                                            selectedAccount: delabelAccount(selectedAccount)
                                        }
                                    }
                                    )
                                }}>upgrade plan</button>

                                {(selectedAccount.energyLog?.length > 0) && (getMaxVisitors() > 0) && <EnergyChart selectedAccount={selectedAccount} />}

                                <label className="content-container-inner-label">choose the green badge type</label>
                                <div className={'content-container-inner'}>
                                    <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                                        <Select options={badgetTypes}
                                            placeholder="Asset type"
                                            className={``}
                                            classNamePrefix="c-select"
                                            onChange={(badget) => { parseSelectedBadget(badget); }}
                                            value={selectedBadget}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>

                                {selectedBadget?.value === "widget" && <>
                                    <label className="content-container-inner-label">here is your widget preview, try closing/hovering/opening it</label>

                                    <div className='badgetPanel'>
                                        <div id='badget-hover-box'></div>
                                        <p id='badget'><p id='badget-box'></p></p>
                                        {/* {setTimeout(() => loadBadget(), 100) && ''} */}
                                    </div>
                                    <div className={'content-container-inner'}>
                                        <p>Select language:</p>
                                        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                                            <Select options={languages}
                                                placeholder="Select Language"
                                                className={``}
                                                classNamePrefix="c-select"
                                                onChange={(lang) => { parseSelectedLanguage(lang); }}
                                                value={selectedLanguage}
                                                isSearchable={false}
                                            />
                                        </div>
                                        <p>Choose widget position:</p>
                                        <div className='checkbuttonContainer m-b-30'>
                                            <input type='radio' checked={!rightWidget} onChange={(e) => {
                                                // getData(cookies.validatedEmails.email);
                                                parseSelectedSide("left");
                                            }} id="leftRadio" />
                                            <label htmlFor="leftRadio" className='checkBoxText'>Left</label>
                                            <input type='radio' checked={rightWidget} onChange={(e) => {
                                                // getData(cookies.validatedEmails.email);
                                                parseSelectedSide("right");
                                            }} id="rightRadio" />
                                            <label htmlFor="rightRadio" className='checkBoxText'>Right</label>
                                        </div>
                                    </div>
                                </>}
                                {selectedBadget?.value === "mark" &&
                                    <Badge account={selectedAccount} />
                                }
                                {selectedBadget?.value === "snippet" &&
                                    <Snippet account={selectedAccount} />
                                }

                                <label className="content-container-inner-label">add badge to your website</label>
                                <div className={'content-container-inner'}>

                                    <p>In order to display green browsing badge on your website, add the following lines to your website. For widget it is recommended to add this html code after opening or before closing html <b>&lt;body&gt;</b> element. For mark and snipet any place should work. Before going live it is recommended to review changes.</p>
                                    <span className={`note ${styles.noteOpaqueAccent}`}>
                                        {
                                            `<!-- -----greeningweb badge start------- -->
                                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato">
                                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bebas%20Neue">
                                    <div id='badget-hover-box'></div>
                                    <p name='${selectedAccount.id}' id='badget'><p id='badget-box'></p></p>
                                    <script src='${config.badgetSource}'></script>
                                    <a href="https://greeningweb.com" style="display:none;" >https://greeningweb.com</a>
                                    <!-- -----greeningweb badge end------- -->`
                                        }

                                    </span>

                                    <LoadingButton onClick={() => testWidget()} buttonText='test badge' isLoading={testing} />
                                </div>

                                {testSuccessState && testSuccessState === 'FAILED' &&
                                    <div className={'content-container-inner'}>
                                        <p className='note'>⛔ Something went wrong. Make sure you have added a badge to the website and try again by pushing a button "TEST BADGE"</p>
                                    </div>
                                }

                                {testSuccessState && testSuccessState === 'SUCCESS' &&
                                    <div className={'content-container-inner'}>
                                        <p className='note'>✔️ Congratulations! You have added the badge to the website successfully. From now on, your website visitors will experience green browsing</p>
                                    </div>
                                }

                            </>}
                        </>

                    }
                </div>
            }
        </>
    );
};

export default MyWidgets;

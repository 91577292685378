import moment from "moment";
import { FC } from "react";
import styles from './Notification.module.css';

type NotificationProps={
    selectedAccount:any;
    monthlyCounter: number;
}

export const Notification:FC<NotificationProps> = (props) =>{
    let PlanLimitReachDate = props.selectedAccount.subscription?.PlanLimitReachDate;
    let SubscriptionStart:Date = props.selectedAccount.subscription?.SubscriptionStart;
    let subscriptionEnding
    let Period:String =  props.selectedAccount.subscription?.Period;
    let currentTime = moment();
    let daysLeft = moment(PlanLimitReachDate).diff(currentTime,'days');
    
    if(Period === "month"){
        subscriptionEnding=moment(SubscriptionStart).add(1, 'months');
    }else if (Period === "year"){
        subscriptionEnding=moment(SubscriptionStart).add(1, 'years');
    }
    let daysUntillSubscriptionEnding = subscriptionEnding?.diff(currentTime,'days');
    let isNearingSubscriptionEnding=daysUntillSubscriptionEnding!<14;

    let isNearingLimitDate:boolean = (daysLeft>=0 && daysLeft<=14 && PlanLimitReachDate && props.monthlyCounter>=props.selectedAccount.subscription?.VisitsLimit);

    return(
        <>
        {isNearingLimitDate?
            <div>
                <div className={'content-container-inner'}>
                <p>You have reached your plans web visit amount. The Widget will become inactive in <b className="accent-color">{daysLeft} days</b>. Upgrade your plan 🌱</p>
                </div>
            </div>
            :
            isNearingSubscriptionEnding?
            <div>
                <div className={'content-container-inner'}>
                <p>Your plan is about to end. The Widget will become inactive in <b className="accent-color">{daysUntillSubscriptionEnding} days</b>.</p>
                </div>
            </div>:
            <></>
        }
        </>
    );
}
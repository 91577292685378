import { Dispatch, FC, SetStateAction } from 'react';
import styles from './ToggleSwitch.module.css';

interface ToggleProps {
    initial: boolean;
    setValue: Dispatch<SetStateAction<boolean>>;
}

const ToggleSwitch: FC<ToggleProps> = ({ initial, setValue }) => {
    return (
        <div className={styles.toggleSwitch}>
            <input type='checkbox' className={styles.checkBox} checked={initial} onChange={event => { setValue(event.target.checked) }} id='toggleBox'></input>
            <label className={styles.label} htmlFor='toggleBox'>
                <span className={styles.inner} />
                <span className={styles.switch} />
            </label>
        </div>
    );
}

export default ToggleSwitch;
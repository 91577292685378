import {
    Color,
    ColorResult,
    CustomPicker,
    InjectedColorProps
} from "react-color";
import { EditableInput, Hue, Saturation } from "react-color/lib/components/common";
import { ExportedColorProps } from "react-color/lib/components/common/ColorWrap";
import style from "./CustomColorPicker.module.css";
const Pointer = () => {
    return <div className="color-picker-pointer"></div>
}

export const ColorPicker = (props: InjectedColorProps) => {
    return (
        <div>
            <div className={style.saturationPicker} >
                <Saturation
                    {...props}
                    pointer={Pointer}
                    onChange={(color: Color | ColorResult) => { props.onChange?.(color) }}
                />
            </div>

            <div className={style.huePicker} >
                <Hue
                    {...props}
                    pointer={Pointer}
                    onChange={(color: Color | ColorResult) => { props.onChange?.(color) }}
                />
            </div>

            <div className={style.editableInputPicker}>
                <label className='input-label no-margin'>Hex:</label>
                <EditableInput
                    value={props.hex}
                    onChange={(color: Color | ColorResult) => props.onChange?.(color)} />


                <div className={style.editableRgb}>
                    <label className='input-label no-margin'>R:</label>
                    <input type='text'
                        value={props.rgb?.r}
                        onChange={(event: any) => {
                            const rgb = props.rgb!;
                            rgb.r = event.target.value;
                            props.onChange?.(rgb)
                        }} />
                    <label className='input-label no-margin'>G:</label>
                    <input type='text'
                        value={props.rgb?.g}
                        onChange={(event: any) => {
                            const rgb = props.rgb!;
                            rgb.g = event.target.value;
                            props.onChange?.(rgb)
                        }} />
                    <label className='input-label no-margin'>B:</label>
                    <input type='text'
                        value={props.rgb?.b}
                        onChange={(event: any) => {
                            const rgb = props.rgb!;
                            rgb.b = event.target.value;
                            props.onChange?.(rgb)
                        }} />
                </div>
            </div>
        </div>
    );
};

const CustomColorPicker = CustomPicker<ExportedColorProps>(ColorPicker);
export { CustomColorPicker };

import { FC, useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import badge from '../../images/badge.svg';
import { getFilterFromHex } from '../../utils/ColorFilter';
import Select from 'react-select';
import { enrexApi } from "../../services/EnrexApiService";
import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";
import { throttle } from "../../utils/Throttle";

interface IBadge {
	account?: any
}
export const Badge: FC<IBadge> = ({ account }) => {
	const sizes = [{ value: 60, label: "1x (200x60px)" }, { value: 75, label: "1.25x (250x75px)" }, { value: 90, label: "1.5x (300x90px)" }]
	const history = useHistory();

	const [selectedSize, setSelectedSize] = useState<any>({ value: 60, label: "1x (200x60px)" })
	const [background, setBackground] = useState<string>()
	const [badgeColor, setBadgeColor] = useState<any>({ hex: '#454DB4', hsl: { h: 235.67, s: 0.44578, l: 0.488 }, hsv: { h: 235.67, s: 0.61666, v: 0.70588 } })

	useEffect(() => {
		if (account && account.badgetSettings?.size) {
			var size = account.badgetSettings?.size
			if (size == 60 || size == 75 || size == 90) {
				setSelectedSize({ value: size, label: `${size / 60}x (${(size / 60) * 200}x${size}px)` })
			} else {
				setSelectedSize({ value: 60, label: "1x (200x60px)" })
			}
		} else {
			setSelectedSize({ value: 60, label: "1x (200x60px)" })
		}
		if (account && account.badgetSettings?.color) {
			console.log(account.badgetSettings?.color)
			setBadgeColor({ hex: account.badgetSettings?.color, hsl: { h: 235.67, s: 0.44578, l: 0.488 }, hsv: { h: 235.67, s: 0.61666, v: 0.70588 } })
		}
		setBackground('light');
	}, [account]);
	const getBackgroundMode = (background: string) => {
		if (background === 'light') {
			return ('#F9F9F9')
		} else {
			return ('#49596B')
		}
	}
	const parseSelectedSize = (size: any, color?: string) => {
		console.log(size, " ", color)
		setSelectedSize(size);
		enrexApi.get("/updateBadgetSettings", {
			params: {
				id: account.id,
				type: 'mark',
				size: size.value,
				color: color,
			}
		})
	}
	const handleColorChange = (color: any) => {
		let hex: string = color.hex
		let size: number = selectedSize.value
		throttle(parseSelectedSize, 1500)(selectedSize, hex)
		setBadgeColor(color)
	};
	return (<>
		<label className="content-container-inner-label">mark preview</label>
		{background &&
			<div className={'content-container-inner'} style={{ textAlign: 'center', background: getBackgroundMode(background) }}>
				<img src={badge} style={{ top: '4px', height: `${selectedSize.value}px`, position: `relative`, filter: `${getFilterFromHex(badgeColor.hex)}` }} onClick={() => { history.push(`/profile/${account.id}`) }}>
				</img>
			</div>}
		{account && <>
			<label className="content-container-inner-label">customise your mark</label>
			<div className={'content-container-inner'}>
				<p>size</p>
				<div style={{ marginBottom: "10px", marginTop: "10px" }}>
					<Select options={sizes}
						placeholder="Asset type"
						className={``}
						classNamePrefix="c-select"
						onChange={(size) => { parseSelectedSize(size); }}
						value={selectedSize}
						isSearchable={false}
					/>
				</div>
				<p>background prevew color</p>
				<div className='checkbuttonContainer m-b-30'>
					<input type='radio' checked={background === 'light'} onChange={(e) => {
						setBackground('light')
					}} id="leftRadio" />
					<label htmlFor="leftRadio" className='checkBoxText'>Light</label>
					<input type='radio' checked={background === 'dark'} onChange={(e) => {
						setBackground('dark')
					}} id="rightRadio" />
					<label htmlFor="rightRadio" className='checkBoxText'>Dark</label>
				</div>
				<p>Choose a color:</p>
				<CustomColorPicker color={badgeColor.hsl} onChange={handleColorChange}></CustomColorPicker>
			</div></>}
	</>
	)
}
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { enrexApi } from "../../services/EnrexApiService";
import styles from "./PlanCancelation.module.css";

export const PlanNotification = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const history = useHistory();
	let state = history.location.state as any;
	const id: string = state.selectedAccountId;
	const planName: string = state.planName;

	//console.log("ID: ", id, " Plan name: ", planName);

	const updatePlan = async (id: string, planName: string) => {
		setIsLoading(true);
		try {
			await enrexApi.post('/subscription/update', null, {
				params: {
					id: id,
					planName: planName,
				},
			}).then(() => {
				//reload page (Temporary)
				setTimeout(() => {
					setIsLoading(false);
					history.push("/planSelection");
				}, 4000);
			})
		} catch (e: any) {
			console.error(e)
		}
	}
	return (
		<div className={`content-container-inner `}>
			<label className="content-container-inner-label">Are you sure you want to change a plan?</label>
			<p>⚠️If upgrading to higher plan, Your credit card will be charged automatically.</p><br />

			<div className={`${styles.buttonContainer}`}>
				<button className={`${styles.grayHoverBorder}`} onClick={() => {
					history.push("/planSelection");
				}}>No, Go Back</button>
				<button className={`greenHoverBorder ${styles.changePlan}`} disabled={false} onClick={() => {
					!isLoading && updatePlan(id, planName);
				}
				}> {!isLoading && "Change A Plan"}{isLoading && (<div className="loadingIndicator dot-flashing inline"></div>)}</button>
			</div>
		</div>
	)
}
import { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

interface LogoutProps {
}

const Logout: FC<LogoutProps> = (props) => {
    const history = useHistory();
    const [cookies, setCookie, removeCookie] = useCookies(['validatedEmails']);

    useEffect(() => {
        if (!cookies.validatedEmails) {
            history.push('/home')
        }
    }, [cookies.validatedEmails]);

    const logout = () => {
        removeCookie('validatedEmails');
        history.push('/home')
    }

    return (
        <>
            <div className="dapp-container center-vertical">
                <label className="content-container-inner-label">are you sure you want to log out?</label>
                <button onClick={() => logout()} className="greenHoverBorder">log out</button>
            </div>
        </>
    )
};

export default Logout;

import { FC, useEffect, useState } from "react";
import { Chart as ChartComponent } from "react-chartjs-2";
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement, ChartConfiguration, ChartData, ChartOptions, Legend, BarController, ScatterController } from 'chart.js';
import { useResize } from "../../utils/Resize";
import style from './EnergyChart.module.css';
import iconBackArrow from '../../images/icon-back-arrow.svg';
import Select from "react-select";
import { options } from "./chartOptions"

interface IChart {
	selectedAccount: any
}

export const EnergyChart: FC<IChart> = ({ selectedAccount }) => {
	const energyLog: { kwh: number, co2AverageMonth: number, offsetCounter: number, date: Date }[] = selectedAccount.energyLog;
	Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement, BarController);
	const [selectedYear, setSelectedYear] = useState<any>()
	const [years, setYears] = useState<any[]>()
	const [data, setData] = useState<ChartData>()
	const [count, setCount] = useState<number>(6)
	const [arrayBits, setArrayBits] = useState<number>(1)
	const screenWidth = useResize();

	useEffect(() => {
		setArrayBits(1);
		initialize();
	}, [energyLog]);

	window.addEventListener('resize', () => {
		let collumnCount = screenWidth > 800 ? 6 : 3;
		setCount(collumnCount)
		setArrayBits(1);
	});

	const initialize = () => {
		let tempYears: any[] = [];

		let tempvisitors = energyLog.map((log) => { return log.offsetCounter }).reverse();
		let tempemission = energyLog.map((log) => { return (log.co2AverageMonth * log.offsetCounter) }).reverse();
		let year: number
		let tempmonths = energyLog.map((log) => {
			if (year !== new Date(log.date).getFullYear()) {
				year = new Date(log.date).getFullYear();
				tempYears.push({ value: year, label: year });
			}
			return new Date((new Date(log.date).getDate() == 1) ? new Date(log.date).setDate(new Date(log.date).getDate() - 1) : log.date).toLocaleString('en-US', { month: 'short' })
		}).reverse();// if date is the first day of month, dislay yesterday

		setYears(tempYears);
		setSelectedYear(tempYears[tempYears.length - 1]);

		let collumnCount = screenWidth > 800 ? 6 : 3;
		setCount(collumnCount)

		tempvisitors.length = collumnCount;
		tempemission.length = collumnCount;
		tempmonths.length = collumnCount;


		setChartData(tempvisitors, tempemission, tempmonths)
	}

	const setChartData = (visit: number[], emiss: number[], mont: string[]) => {
		visit.reverse();
		emiss.reverse();
		mont.reverse();
		setData({
			labels: mont,
			datasets: [{
				type: 'bar',
				label: 'Visits mer month',
				data: visit,
				backgroundColor: '#54D3D0',
				yAxisID: 'yAxisLeft',
				order: 2,
				barThickness: 40,
				//lineTension: 0
			}, {
				type: 'line' as any,
				label: 'Total CO2, per month',
				data: emiss,
				borderColor: '#FF7E7E',
				backgroundColor: '#FF7E7E',
				fill: false,
				order: 1,
				//lineTension: 0,
				yAxisID: 'yAxisRight',
			}]
		});
	}

	const scrollColumns = (scroll: string) => {
		let tempvisitors = energyLog.map((log) => { return log.offsetCounter }).reverse();
		let tempemission = energyLog.map((log) => { return (log.co2AverageMonth * log.offsetCounter) }).reverse();
		let tempmonths = energyLog.map((log) => {
			return new Date((new Date(log.date).getDate() == 1) ? new Date(log.date).setDate(new Date(log.date).getDate() - 1) : log.date).toLocaleString('en-US', { month: 'short' })
		}).reverse();
		if (arrayBits >= 1 && tempvisitors.length >= count && tempemission.length >= count && tempmonths.length >= count) {
			let scrollCount: number;
			if (scroll == "left") {
				scrollCount = arrayBits + 1
			}
			else {
				scrollCount = arrayBits - 1
			}
			if (scrollCount != 0 && (count * scrollCount) < energyLog.length + count) {
				setArrayBits(scrollCount)
				tempvisitors.length = count * scrollCount;
				console.log(tempvisitors)
				tempvisitors = tempvisitors.slice(-count)
				console.log(tempvisitors)
				tempemission.length = count * scrollCount;
				tempemission = tempemission.slice(-count)
				tempmonths.length = count * scrollCount;
				tempmonths = tempmonths.slice(-count)
				setChartData(tempvisitors, tempemission, tempmonths)
			}
		}
	}

	const parseSelectedYear = async (value: any) => {
		setSelectedYear(value);
		let startLog;
		let tempvisitors;
		let tempemission;
		let tempmonths;
		let end: boolean = false;
		energyLog.forEach((log, index) => {
			if (value.value == new Date(log.date).getFullYear() && !end) {
				end = true
				startLog = energyLog.slice(index, index + count);
				console.log(index, index + count)
				tempvisitors = startLog.map((i) => { return i.offsetCounter }).reverse();
				tempemission = startLog.map((i) => { return (i.co2AverageMonth * i.offsetCounter) }).reverse();
				tempmonths = startLog.map((i) => {
					return new Date((new Date(i.date).getDate() == 1) ? new Date(i.date).setDate(new Date(i.date).getDate() - 1) : i.date).toLocaleString('en-US', { month: 'short' })

				}).reverse();
				setArrayBits(energyLog.length / count)
				setChartData(tempvisitors, tempemission, tempmonths)
				return;
			}
			return;
		})
	}

	return (
		<>
			<label className="content-container-inner-label">STATISTICS</label>
			<div className="content-container-inner">
				<div className={style.legendsection}>
					<div className={style.leftContainer}>
						<div className={style.colorLable} style={{ backgroundColor: '#54D3D0' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;
						</div>
						Visits mer month
					</div>
					<div className={style.rightContainer}>
						<div className={style.colorLable} style={{ backgroundColor: '#FF7E7E' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;
						</div>
						Total CO2 grams, per month
					</div>
				</div>
				<div style={{ height: '355px' }}>
					{data && <ChartComponent options={options} type='bar' data={data} />}
				</div>
				<div className={style.buttonsection}>
					<div className={style.leftContainer}>
						<button className={`greenHoverBorder ${style.button}`} disabled={false} onClick={() => scrollColumns("left")}>
							{<img className={style.arrow} src={iconBackArrow} />}</button>
					</div>
					<div className={style.centerContainer}>
						<Select options={years}
							className={``}
							classNamePrefix="c-select"
							onChange={(year) => { parseSelectedYear(year); }}
							value={selectedYear}
							isSearchable={false}
						/>
					</div>
					<div className={style.rightContainer}>
						<button className={`greenHoverBorder ${style.button}`} disabled={false} onClick={() => scrollColumns("right")}>
							{<img className={style.nextArrow} src={iconBackArrow} />}</button>
					</div>
				</div>
			</div>
		</>
	);
};

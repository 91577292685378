
export const timesPerYear = 120000;

export const getEnergyKwh = (websiteData: any, perYear = false) => {
    let result = websiteData.kwh;
    if (perYear) {
        result = result * timesPerYear;
    }
    return result.toFixed(4)
}

export const getEnergyWh = (websiteData: any, perYear = false) => {
    let result = websiteData.kwh * 1000;
    if (perYear) {
        result = result * timesPerYear;
    }
    return result.toFixed(4)
}

export const getCO2Grams = (websiteData: any, perYear = false) => {
    let result = websiteData.co2;
    if (perYear) {
        result = result * timesPerYear;
    }
    return result.toFixed(4)
}

export const getCupsOfTea = (websiteData: any, perYear = false) => {
    let result = websiteData.kwh / 0.02208;
    if (perYear) {
        result = result * timesPerYear;
    }
    return result.toFixed(2)
}

export const getLedLightMinutes = (websiteData: any, perYear = false) => {
    let result = websiteData.kwh * 1000 / 10 * 60;
    if (perYear) {
        result = result * timesPerYear;
    }
    return result.toFixed(2)
}

export const getTrees = (websiteData: any, perYear = false) => {
    let result = getCO2Grams(websiteData) / 1000 / 25;
    if (perYear) {
        result = result * timesPerYear;
    }
    return result.toFixed(4)
}
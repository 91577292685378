import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { enrexApi } from '../../services/EnrexApiService';
import { useResize } from '../../utils/Resize';
import ConsumptionPanel from '../Consumption/ConsumptionPanel';

const Profile = () => {
	const screenWidth = useResize();
	const { id }: { id: string } = useParams();
	const [account, setAccount] = useState<any>()

	useEffect(() => {
		getAccountDetails(id)
	}, [id])

	const getAccountDetails = async (id: string) => {
		let account = await enrexApi.get("/accountInfo", {
			params: {
				id: id,
			},
		})
		console.log(account);
		setAccount(account.data);
	}
	return (<>
		{account &&
			<div className="dapp-container">
				<label className="content-container-inner-label"><label className="label-accent">{account.url}</label> uses renewable energy, so your stay here is beneficial for the environment 🌎</label>
				<label className="content-container-inner-label">Your presence on this website is covered with renewable energy for carbon-neutral browsing.</label>
				<ConsumptionPanel websiteData={account} open={true} />
			</div>}
	</>
	);
}

export default Profile;
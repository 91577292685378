interface ImageSelectorProps {
    test: any, 
    ifTrueImg: any, 
    ifFalseImg: any,
    className?: any
}

const ImageSelector: React.FunctionComponent<ImageSelectorProps> = (props) =>{
    return (<img src={props.test ? props.ifTrueImg : props.ifFalseImg} className={props.className} />);
}

export default ImageSelector;
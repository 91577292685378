import { useHistory } from 'react-router-dom';
import CancelPlanStyles from './CancelPlan.module.css';
import iconBackArrow from '../../images/icon-back-arrow.svg';
import { useState } from 'react';
import { enrexApi } from '../../services/EnrexApiService';

const CancelPlan = () =>{
    const history = useHistory();
    const [reason, setReason] = useState<string>('');
    const [isInputError, setInputError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    let state = history.location.state as any;
    const id:string = state.selectedAccountId;

    const cancel=async (message:string,id: string)=>{
        if(message)
        {
            setIsLoading(true);
            try{
            console.log(id);
            //API endpoint /subscription/cancel
            await enrexApi.post('/subscription/cancel',null ,{
               params: {
                   id:id,
                   reason:message,
               },
            }).then(()=>{
                setTimeout(()=>{
                    setIsLoading(false);
                    history.push("/planSelection");
                },4000)})
            }
            catch(e: any){
                console.error(e.response.data);
            }
        }else{
            setInputError(true);
        }
    }

    const changeInput=(reason:string)=>{
        setReason(reason)
        if(reason==''){
            setInputError(true);
            return;
        }
        
        setInputError(false);
    }

    return(
    <div className="dapp-container">
        <div className="content-container-inner">
            <label className="content-container-inner-label">We are sorry to see you go</label>
            <p>If you would like to proceed with canceling your subscripcion, please <a className="accent-color">enter the reason</a> and select "Cancel plan" below.</p>
            <div className={CancelPlanStyles.inputErea}>
            <input type="text"
                placeholder="Enter reason"
                onChange={(event: any) => { changeInput(event.target.value) }}
                value={reason}
                className={`${isInputError ? 'invalid' : ''}`}
            />
            </div>
            <button className={`greenHoverBorder ${CancelPlanStyles.backButton}`} onClick={() => {history.push('/planSelection')}}><img className={CancelPlanStyles.backArrow} src={iconBackArrow} /></button>
            <button className={`${CancelPlanStyles.cancelButton} ${reason?'greenHoverBorder':''}`} onClick={() => {cancel(reason,id)}}> {!isLoading && "Cancel Plan"}{isLoading && (<div className="loadingIndicator dot-flashing inline"></div>)} </button>
        </div>
    </div>
    )
}

export default CancelPlan;

import discountMessageStyles from './DiscountMessage.module.css';

interface DiscountMessagProps{
    message: string, 
    className?: any
}

const DiscountMessage: React.FunctionComponent<DiscountMessagProps> = (props) => {
    return(
        <div className={`${discountMessageStyles.container} ${props.className}`}>
            {props.message}
        </div>
    );
}

export default DiscountMessage;
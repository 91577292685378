import axios from "axios";
import config from "../../config";
import { getLedLightMinutes } from "../../utils/ConsumptionUtils";


export const badgetPreview = async (badgetUrl: string, kwh: any, co2: any, side: string, language: string = "en", isMobile?: boolean) => {
    //language
    let languageAssets = axios.create({
        baseURL: config.enrexLanguages,
        // timeout: 1000,
    });
    const result = await languageAssets.get(`/${language}.json`);
    const languageOption = result.data;

    //changed
    let oldBadget: any = document.getElementById('badget');
    let newBadget: any = oldBadget?.cloneNode(true);
    oldBadget.parentNode?.replaceChild(newBadget, oldBadget);

    deleteBadgetBox();

    var badgetWidth: number
    var badgetHeight: number
    var circleTop: number
    var circleHeigth: number
    var iconFont: string
    var energyTop: number
    var textBoxFont1: string
    var textBoxFont2: string
    var textBoxFont3: string
    var textBoxFont4: string
    var textBoxFont5: string
    var textBoxFont6: string
    var textBoxTop: number
    var textBoxSpace: number
    var textBoxSpace2: number
    var textBoxSpace3: number
    var badgetboxHeight: number
    var badgetTextHeight: number
    var badgetHeightOffset: number
    var dashlineTop: number
    var leafOffset: number
    //mobile-web app
    if (isMobile) {
        badgetWidth = 300
        badgetHeight = 395

        iconFont = "20px/27px"
        energyTop = -70

        circleTop = -88
        circleHeigth = 190

        //textbox fonts
        textBoxFont1 = "14px/20px"
        textBoxFont2 = "20px/27px"
        textBoxFont3 = "14px/20px"
        textBoxFont4 = "12px/18px"
        textBoxFont5 = "11px/15px"
        textBoxFont6 = "11px/16px"

        textBoxTop = 20
        textBoxSpace = 30
        textBoxSpace2 = 50
        textBoxSpace3 = 60

        badgetboxHeight = 50
        badgetTextHeight = 5
        badgetHeightOffset = 583
        dashlineTop = 333

        leafOffset = 7
    } else {
        badgetWidth = 340
        badgetHeight = 455

        iconFont = "24px/31px"
        energyTop = -80

        circleTop = -101
        circleHeigth = 220

        //textbox fonts
        textBoxFont1 = "16px/26px"
        textBoxFont2 = "24px/30px"
        textBoxFont3 = "16px/24px"
        textBoxFont4 = "14px/22px"
        textBoxFont5 = "12px/18px"
        textBoxFont6 = "12px/18px"

        textBoxTop = 0
        textBoxSpace = 0
        textBoxSpace2 = 15
        textBoxSpace3 = 5

        badgetboxHeight = 58
        badgetTextHeight = 0
        badgetHeightOffset = 575
        dashlineTop = 385

        leafOffset = 0
    }

    //changed
    document.getElementById('badget')!.style.cssText = `width:${badgetboxHeight}px; height:${badgetboxHeight}px; border-radius:50%; background-color:#37CCC8; position:absolute; bottom:${isMobile ? 16 : 32}px; ${side}:calc(50% - 170px); z-index:1000; box-shadow: 0px 3px 6px #49596B26; cursor: pointer;`;

    // changed
    let leaf = document.getElementById('widgetImg') as HTMLImageElement;
    if (!leaf) {
        leaf = document.createElement('img');
        leaf.src = 'https://assets.enrex.io/badget/images/icon-leaf.svg';
        leaf.style.cssText = `display: block; margin:auto; position:relative; top:16px; width: ${32 - leafOffset}px; z-index:100;`;
        leaf.id = 'widgetImg'
        document.getElementById('badget')!.appendChild(leaf);
    }

    var badgetOpened = false;
    var badgetAnimating = false;
    var badgetAnimation: any = null;
    var badgetFloatBoxWidth = 60;
    var badgetFloatBoxOpacity = 0;
    var badgetBoxAnimation: any = null;
    var badgetBoxOpacity = 0;
    var boxArray: any[] = [];
    var badgetHoverBox = document.getElementById('badget-hover-box')!;

    document.getElementById('badget')!.addEventListener('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        if (badgetAnimating) {
            return;
        }
        badgetAnimating = true;

        if (!badgetOpened) {
            boxArray = []
            const badgetBox = document.getElementById('badget-box')!;
            //changed
            badgetBox.style.cssText = `width:${badgetWidth}px; height:${badgetHeight}px; background-color: #54D3D0; position:absolute; bottom:80px; ${side}:0px; border-radius: 16px; box-shadow: 0px 3px 6px #49596B33; opacity: 0;`;
            boxArray.push(badgetBox);

            const whiteBox = document.createElement('span');
            whiteBox.style.cssText = `margin: 0px; display: inline; width:${badgetWidth}px; height:${circleHeigth}px; background-color:white; border-radius: 16px 16px 40px 40px; margin: 0px; position:absolute; box-shadow: 0px 3px 6px #49596B33; opacity: 0;`;
            document.getElementById('badget-box')!.appendChild(whiteBox);
            boxArray.push(whiteBox);

            let coalEnergyFont = `font: normal normal normal ${iconFont} Bebas Neue; letter-spacing: 0px; color: #49596B;`;

            const energyIcon = document.createElement('div');
            energyIcon.style.cssText = `margin: 0px; display: inline; width: 100%; text-align: center; z-index: 100 !important; position: absolute; left:0px; top:${energyTop}px; opacity: 0;`;
            let energy = document.createElement('p');
            energy.style.cssText = coalEnergyFont;
            energy.innerHTML = '⚡';
            let energyText = document.createElement('p');
            energyText.style.cssText = coalEnergyFont;
            energyText.innerHTML = (kwh * 1000).toFixed(4) + ' Wh';
            energyIcon.appendChild(energy)
            energyIcon.appendChild(energyText)
            whiteBox.appendChild(energyIcon);
            boxArray.push(energyIcon);

            const coalIcon = document.createElement('div');
            coalIcon.style.cssText = `margin: 0px; display: inline; width: 100%; text-align: center; z-index: 100 !important; position: absolute; left:0px; top:10px; opacity: 0;`;
            let coal = document.createElement('p');
            coal.style.cssText = coalEnergyFont;
            coal.innerHTML = '☁️';
            let coalText = document.createElement('p');
            coalText.style.cssText = coalEnergyFont;
            coalText.innerHTML = co2.toFixed(2) + ' g of CO<sub>2</sub>';
            coalIcon.appendChild(coal)
            coalIcon.appendChild(coalText)
            whiteBox.appendChild(coalIcon);
            boxArray.push(coalIcon);

            const circle = document.createElement('img');
            circle.src = 'https://assets.enrex.io/badget/images/icon-circle.svg';
            circle.style.cssText = `margin: 0px; display: inline; margin:auto; position:absolute; left: 52px; top:${circleTop}px; opacity: 0; height: ${circleHeigth}px;`
            whiteBox.appendChild(circle);
            boxArray.push(circle);

            const textBox1 = document.createElement('p');
            textBox1.style.cssText = `margin: 0px; display: inline; width: 100%; font: normal normal bold ${textBoxFont1} Lato; letter-spacing: 0px; color: #49596B; text-align: center; position:absolute; left:0px; top:calc(120px - ${textBoxTop}px); opacity: 0;`;
            textBox1.className = 'latoRegular';
            textBox1.innerHTML = languageOption.LEDText1;
            whiteBox.appendChild(textBox1);
            boxArray.push(textBox1);

            const textBox2 = document.createElement('p');
            textBox2.style.cssText = `margin: 0px; display: inline; width: 100%; font: normal normal normal ${textBoxFont2} Bebas Neue; letter-spacing: 0px; color: #49596B; text-align: center; position:absolute; left:0px; top:calc(144px - ${textBoxTop}px); opacity: 0;`;
            let LEDlightMinutes = ((kwh * 1000) * 6).toFixed(1);
            if (Number(LEDlightMinutes) > 1) {
                textBox2.innerHTML = LEDlightMinutes + ' ' + languageOption.LEDText2 + ' <br/> 💡';
            } else {
                textBox2.innerHTML = Number(LEDlightMinutes) * 60 + ' seconds of led light <br/> 💡';
            }
            whiteBox.appendChild(textBox2);
            boxArray.push(textBox2);

            const textBox3 = document.createElement('p');
            textBox3.style.cssText = `margin: 0px; display: inline-block; width: 100%; font: normal normal 900 ${textBoxFont3} Lato; letter-spacing: 0px; color: #49596B; text-align: center; margin-top: calc(240px - ${textBoxSpace}px); opacity: 0;`;
            textBox3.className = 'latoRegular';
            if (badgetUrl.includes('https')) {
                textBox3.innerHTML = badgetUrl.substring(8).split('/')[0];
            } else if (badgetUrl.includes('https')) {
                textBox3.innerHTML = badgetUrl.substring(7).split('/')[0];
            } else {
                textBox3.innerHTML = badgetUrl.split('/')[0];
            }
            document.getElementById('badget-box')!.appendChild(textBox3);
            boxArray.push(textBox3);

            const textBox4 = document.createElement('p');
            textBox4.style.cssText = `margin: 0px; display: inline; width: 100%; font: normal normal 500 ${textBoxFont4} Lato; letter-spacing: 0px; color: #49596B; text-align: center; position:absolute; left:0px; top:calc(269px - ${textBoxSpace}px); opacity: 0;`;
            textBox4.className = 'latoRegular';
            textBox4.innerHTML = languageOption.reason + '🌎';
            document.getElementById('badget-box')!.appendChild(textBox4);
            boxArray.push(textBox4);

            const textBox5 = document.createElement('p');
            textBox5.style.cssText = `margin: 0px; display: inline; width: 100%; font: normal normal ${textBoxFont5} Lato; letter-spacing: 0px; color: #49596B; text-align: center; position:absolute; left:0px; top:calc(340px - ${textBoxSpace2}px); opacity: 0;`;
            textBox5.className = 'latoRegular';
            textBox5.innerHTML = languageOption.description;
            document.getElementById('badget-box')!.appendChild(textBox5);
            boxArray.push(textBox5);

            const dashedLine = document.createElement('pr');
            dashedLine.style.cssText = `display: inline; width:100%; height:1px; position:absolute; top:${dashlineTop}px; left:0px; background-color:white; opacity: 0.5;`;
            document.getElementById('badget-box')?.appendChild(dashedLine);

            const textBox6 = document.createElement('p');
            textBox6.style.cssText = `margin: 0px; display: inline; width: 100%; font: normal normal normal ${textBoxFont6} Lato; letter-spacing: 0px; color: #49596B; text-align: center; position:absolute; left:0px; top:calc(405px - ${textBoxSpace3}px); opacity: 0;`;
            textBox6.className = 'latoRegular';
            textBox6.innerHTML = languageOption.linkToWebsite;
            document.getElementById('badget-box')!.appendChild(textBox6);
            boxArray.push(textBox6);

            const exrexBox = document.createElement('p');
            exrexBox.style.cssText = `margin: 0px; display: inline-block; font: normal normal normal 12px/18px Lato; letter-spacing: 0px; color: #49596B; text-align: center; position:absolute; left:93px; top:calc(425px - ${textBoxSpace3}px); opacity: 0;`;
            exrexBox.className = 'latoRegular';
            exrexBox.innerHTML = 'powered by <a href="https://enrex.io/" style="text-decoration: none; color: #49596B;"><img style="position:relative; top:5px;" src="https://assets.enrex.io/badget/images/icon-enrex.svg" ></img></a>';
            document.getElementById('badget-box')!.appendChild(exrexBox);
            boxArray.push(exrexBox);

            leaf.src = 'https://assets.enrex.io/badget/images/icon-x.svg';
            leaf.style.cssText = `display: block; margin:auto; position:relative; top:19px; width: ${20 - leafOffset}px;`;
            badgetOpened = true;

            clearInterval(badgetBoxAnimation);
            badgetBoxAnimation = setInterval(badgetBoxFadeIn, 10);
        } else {
            badgetOpened = false;
            leaf.src = 'https://assets.enrex.io/badget/images/icon-leaf.svg';
            leaf.style.cssText = 'display: block; margin:auto; position:relative; top:16px;';

            clearInterval(badgetBoxAnimation);
            badgetBoxAnimation = setInterval(badgetBoxFadeOut, 10);
        }
    });


    document.getElementById('badget')!.click();

    document.getElementById('badget')!.addEventListener('mouseover', function () {
        badgetHoverBox.style.cssText = `display:inline; width:${badgetWidth}px; height:${badgetboxHeight}px; margin:0px; background-color: white; position:absolute; bottom:${isMobile ? 15 : 30}px; ${side}:calc(50% - 170px); border-radius: 40px; border: 2px solid #37CCC8; text-align: left; color:#49596B; overflow:hidden;`;
        if (!badgetOpened) { leaf.src = 'https://assets.enrex.io/badget/images/icon-info.svg' };
    });
    document.getElementById('badget')!.addEventListener('mouseleave', function () {
        badgetHoverBox.style.display = 'none';
        if (!badgetOpened) { leaf.src = 'https://assets.enrex.io/badget/images/icon-leaf.svg' };
    });
    badgetHoverBox.style.cssText = `display:none; width:${badgetWidth}px; height:${badgetboxHeight}px; margin:0px; background-color: white; position:absolute; bottom:${isMobile ? 15 : 30}px; ${side}:calc(50% - 170px); border-radius: 40px; border: 2px solid #37CCC8; text-align: center; color:#49596B; overflow:hidden;`;
    let mess = languageOption.statusMessage as string;
    let messStart = mess.substring(0, mess.indexOf("<span"))
    let messMiddle = mess.substring(mess.indexOf("\">") + 2, mess.indexOf("</span>"))
    let messEnd = mess.substring(mess.indexOf("</span>") + 7)
    badgetHoverBox.innerHTML = `<span style="position:absolute; top:calc(22px - ${badgetTextHeight}px); ${side}:10px; white-space: nowrap; width: 270px; text-align: ${side === "left" ? "right" : "left"}; font: normal normal normal ${languageOption.statusMessageSize}px/16px Bebas Neue;"> ${messStart} <span style="color: #37CCC8; font: normal normal normal ${languageOption.statusMessageSize}px/16px Bebas Neue;"> ${messMiddle} </span> ${messEnd} </span>`;

    setTimeout(function () {
        badgetAnimation = setInterval(badgetToolTipFadeIn, 10);
    }, 3000);
    setTimeout(function () {
        badgetAnimation = setInterval(badgetToolTipFadeOut, 10);
    }, 5000);

    function deleteBadgetBox() {
        const badgetBox = document.getElementById('badget-box')!;
        while (badgetBox.firstChild) {
            badgetBox.removeChild(badgetBox.lastChild!);
            document.getElementById('badget-box')!.style.cssText = '';
        }
    }

    function badgetExtensionAnimate() {
        if (badgetFloatBoxWidth >= 320) {
            clearInterval(badgetAnimation);
        } else {
            badgetFloatBoxWidth += 10;
            badgetHoverBox.style.display = 'inline';
            badgetHoverBox.style.width = badgetFloatBoxWidth + 'px';
        }
    }

    function badgetContractionAnimate() {
        if (badgetFloatBoxWidth < 60) {
            badgetHoverBox.style.display = 'none';
            clearInterval(badgetAnimation);
        } else {
            badgetFloatBoxWidth -= 10;
            badgetHoverBox.style.width = badgetFloatBoxWidth + 'px';
        }
    }

    function badgetToolTipFadeIn() {
        if (badgetFloatBoxOpacity >= 1) {
            clearInterval(badgetAnimation);
        } else {
            badgetFloatBoxOpacity += 0.05;
            if (badgetFloatBoxOpacity > 1) {
                badgetFloatBoxOpacity = 1;
            }
            badgetHoverBox.style.display = 'inline';
            badgetHoverBox.style.opacity = badgetFloatBoxOpacity + '';
        }
    }

    function badgetToolTipFadeOut() {
        if (badgetFloatBoxOpacity <= 0) {
            badgetHoverBox.style.display = 'none';
            clearInterval(badgetAnimation);
        } else {
            badgetFloatBoxOpacity -= 0.05;
            if (badgetFloatBoxOpacity < 0) {
                badgetFloatBoxOpacity = 0;
            }
            badgetHoverBox.style.opacity = badgetFloatBoxOpacity + '';
        }
    }

    function badgetBoxFadeOut() {
        if (badgetBoxOpacity <= 0) {
            clearInterval(badgetBoxAnimation);
            deleteBadgetBox();
            badgetAnimating = false;
        } else {
            badgetBoxOpacity -= 0.05;
            if (badgetBoxOpacity < 0) {
                badgetBoxOpacity = 0;
            }
            for (var i = 0; i < boxArray.length; i++) {
                boxArray[i].style.opacity = badgetBoxOpacity + "";
            }
        }
    }

    function badgetBoxFadeIn() {
        if (badgetBoxOpacity >= 1) {
            clearInterval(badgetBoxAnimation);
            badgetAnimating = false;
        } else {
            badgetBoxOpacity += 0.05;
            if (badgetBoxOpacity > 1) {
                badgetBoxOpacity = 1;
            }
            for (var i = 0; i < boxArray.length; i++) {
                boxArray[i].style.opacity = badgetBoxOpacity + "";
            }
        }
    }
}